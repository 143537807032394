import {
  DeleteEditorLabelRequesting,
  DeleteEditorLabelSuccess,
  DeleteEditorLabelError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function DeleteEditorLabel(state = defalutState, action) {
  switch (action.type) {
    case DeleteEditorLabelRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case DeleteEditorLabelSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case DeleteEditorLabelError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
