import {
  GetHourListRequesting,
  GetHourListSuccess,
  GetHourListError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetHourList(state = defalutState, action) {
  switch (action.type) {
    case GetHourListRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetHourListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetHourListError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
