import {
  AddFileRequesting,
  AddFileSuccess,
  AddFileError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function AddFile (state=defalutState,action){
  switch(action.type){
    case AddFileRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case AddFileSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case AddFileError:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    default:
      return state
  }
}
