import {
  GetTaskListRequesting,
  GetTaskListSuccess,
  GetTaskListError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function GetTaskList (state=defalutState,action){
  switch(action.type){
    case GetTaskListRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetTaskListSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetTaskListError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
