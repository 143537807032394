import {
  DeleteLabelTypeRequesting,
  DeleteLabelTypeSuccess,
  DeleteLabelTypeError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function DeleteLabelType (state=defalutState,action){
  switch(action.type){
    case DeleteLabelTypeRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case DeleteLabelTypeSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case DeleteLabelTypeError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
