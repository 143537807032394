import {
  GetMarkQuestionRequesting,
  GetMarkQuestionSuccess,
  GetMarkQuestionError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetMarkQuestion(state = defalutState, action) {
  switch (action.type) {
    case GetMarkQuestionRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetMarkQuestionSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetMarkQuestionError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
