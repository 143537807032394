import {
  PathNodeBookRequesting,
  PathNodeBookSuccess,
  PathNodeBookError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function PathNodeBook(state = defalutState, action) {
  switch (action.type) {
    case PathNodeBookRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case PathNodeBookSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case PathNodeBookError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
