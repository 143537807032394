import {
  PostPassWordRequesting,
  PostPassWordSuccess,
  PostPassWordError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function PostPassWord(state = defalutState, action) {
  switch (action.type) {
    case PostPassWordRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case PostPassWordSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case PostPassWordError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
