import {
  DeleteEditorWorkRequesting,
  DeleteEditorWorkSuccess,
  DeleteEditorWorkError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function DeleteEditorWork(state = defalutState, action) {
  switch (action.type) {
    case DeleteEditorWorkRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case DeleteEditorWorkSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case DeleteEditorWorkError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
