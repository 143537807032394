import {
  GetLessonListRequesting,
  GetLessonListSuccess,
  GetLessonListError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetLessonList(state = defalutState, action) {
  switch (action.type) {
    case GetLessonListRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetLessonListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetLessonListError:
      return Object.assign({}, state, {
        loading: false,
        data: null
      });
    default:
      return state;
  }
}
