import {
  GetFolderOneRequesting,
  GetFolderOneSuccess,
  GetFolderOneError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function GetFolderOne (state=defalutState,action){
  switch(action.type){
    case GetFolderOneRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetFolderOneSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetFolderOneError:
      return Object.assign({},state,{
        loading:false,
        data:null
      })
    default:
      return state
  }
}
