import {
  DeteleHourRequesting,
  DeteleHourSuccess,
  DeteleHourError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function DeteleHour (state=defalutState,action){
  switch(action.type){
    case DeteleHourRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case DeteleHourSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case DeteleHourError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
