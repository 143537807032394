import {
  GetEditorBaseRequesting,
  GetEditorBaseSuccess,
  GetEditorBaseError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetEditorBase(state = defalutState, action) {
  switch (action.type) {
    case GetEditorBaseRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetEditorBaseSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetEditorBaseError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
