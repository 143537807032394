import {
  GetAskQuestionsRequesting,
  GetAskQuestionsSuccess,
  GetAskQuestionsError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetAskQuestions(state = defalutState, action) {
  switch (action.type) {
    case GetAskQuestionsRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetAskQuestionsSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetAskQuestionsError:
      return Object.assign({}, state, {
        loading: false,
        data: null
      });
    default:
      return state;
  }
}
