import {
  GetBookWorkRequesting,
  GetBookWorkSuccess,
  GetBookWorkError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function GetBookWork (state=defalutState,action){
  switch(action.type){
    case GetBookWorkRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetBookWorkSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetBookWorkError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
