import {
  AddEditorDimRequesting,
  AddEditorDimSuccess,
  AddEditorDimError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function AddEditorDim(state = defalutState, action) {
  switch (action.type) {
    case AddEditorDimRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case AddEditorDimSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case AddEditorDimError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
