import {
  PatchEditorMaterialRequesting,
  PatchEditorMaterialSuccess,
  PatchEditorMaterialError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function PatchEditorMaterial(state = defalutState, action) {
  switch (action.type) {
    case PatchEditorMaterialRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case PatchEditorMaterialSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case PatchEditorMaterialError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
