import {
  PatchHourRequesting,
  PatchHourSuccess,
  PatchHourError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function PatchHour (state=defalutState,action){
  switch(action.type){
    case PatchHourRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case PatchHourSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case PatchHourError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
