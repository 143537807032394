import {
  GetFolderTwoRequesting,
  GetFolderTwoSuccess,
  GetFolderTwoError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function GetFolderTwo (state=defalutState,action){
  switch(action.type){
    case GetFolderTwoRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetFolderTwoSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetFolderTwoError:
      return Object.assign({},state,{
        loading:false,
        data:null
      })
    default:
      return state
  }
}
