import { message } from 'antd';

import { loginSuccess } from './store/actionType';
import store from './store/index';

let Documents = document;

// 是否为本地
export function isLocal() {
  let port = window.location.port;
  let hostHome = window.location.hostname;
  if (
    hostHome === '127.0.0.1' ||
    hostHome === 'localhost' ||
    port === '3000' ||
    port === '3001'
  ) {
    return true;
  } else {
    return false;
  }
}

// 取cookie
export function getCookies(name) {
  let localCookies = Documents.cookie.split('; ');
  for (let i = 0; i < localCookies.length; i++) {
    let cookieV = localCookies[i].split('=');
    if (cookieV[0] === name) {
      if (cookieV[1].length <= 2) {
        return null;
      } else {
        return cookieV[1];
      }
    }
  }
}
// 修改cookie
export function setCookites(cookie) {
  Documents.cookie = 'jwt_token=' + cookie + ';';
}
// 取token
export function getCookie() {
  if (isLocal()) {
    return localStorage.getItem('spikeToken');
  } else {
    let getStoreToken = store.getState().login.token;
    let getCookieTo = getCookies('jwt_token');
    if (getStoreToken) {
      return getStoreToken;
    } else if (getCookieTo) {
      // 先存起来
      getStore({ type: loginSuccess, value: getCookieTo });
      return getCookieTo;
    } else {
      return null;
    }
  }
}

// 改变 store
export function getStore(data) {
  store.dispatch(data);
}

function getIp() {
  if (isLocal()) {
    return 'http://localhost:8000/';
    // return 'http://192.168.31.83:8000/';
  } else {
    // return 'http://localhost:8000/'
    return window.location.protocol + '//' + window.location.host + '/';
  }
}

export const URL = getIp();

// 判断权限人员

export function isUser(data, user) {
  if (data.length !== 0 && user) {
    if (data.indexOf(user) !== -1) return true;
  } else {
    return false;
  }
}

export function requestStatus(data, text) {
  if (!text) text = '';
  // message.destroy()
  if (data) {
    if (data.status) {
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        // message.success(`${text}成功`)
      } else if (data.status === 401) {
        let textCode = JSON.stringify(data.data);
        message.error(`${text}失败,${textCode}`);
      } else if (data.status === 403) {
        let text = data.data[Object.keys(data.data)];
        message.error(text);
        if (text === '权限不足') {
          window.location.href = `${window.location.protocol}//${window.location.host}/`;
        }
      } else if (data.status === 404) {
        message.error(`${text}状态码：${data.data[Object.keys(data.data)]}`);
      } else if (data.status === 400) {
        message.error(`${text}状态码：${data.data[Object.keys(data.data)][0]}`);
      } else {
        message.error(`${text}状态码：${data.status}----发生未知错误`);
      }
    } else {
      message.error('发送请求失败！');
    }
  } else {
    message.error(`${text}状态码：${data}----发生未知错误`);
  }
  return data;
}

// 判断后缀名 （默认文件名，期待后缀名）

export function isName(fileName, suffix) {
  let name = fileName.substring(
    fileName.length - suffix.length,
    fileName.length
  ); // 截取文件名后**位（截取多少位，取决于期待值的length）
  if (name === suffix) {
    return true;
  } else {
    return false;
  }
}

// 文件URL

export function returnFileUrl(url) {
  if (!url) return url;
  let newUrl = url.substring(0, 5);
  if (newUrl === 'https') {
    return url;
  } else if (newUrl === 'data:') {
    return url;
  } else {
    return URL + url;
  }
}

// 截取某个特殊字符后的所有字符

export function subToSting(data, text) {
  let values;
  if (data && text) {
    let indexTo = data.indexOf(text);
    values = data.substring(indexTo + 1, data.length);
  } else {
    values = 0;
  }
  return values;
}

// 处理utc时间

export function format(num) {
  var f = num < 10 ? '0' + num : num;
  return f;
}

export function times(utc_str) {
  if (!utc_str) return null;
  let date_obj = new Date(utc_str);
  let time = date_obj.getTime() + 8 * 60 * 60 * 1000;
  date_obj = new Date(time);

  let year = date_obj.getFullYear(); // 年
  let month = date_obj.getMonth() + 1; //月
  let day = date_obj.getDate(); //  日
  let hours = date_obj.getHours(); //  时
  let minutes = date_obj.getMinutes(); //  分
  let utcDataTime = {
    time: time, /// 时间戳
    utcYear: year,
    utcMonth: month,
    utcDate: day,
    utcHours: hours,
    utcMinUtes: minutes,
    utcHM: format(hours) + ' : ' + format(minutes),
    utcYMD: year + '-' + format(month) + '-' + format(day),
    utcYMDHM:
      year +
      '-' +
      format(month) +
      '-' +
      format(day) +
      ' ' +
      format(hours) +
      ' : ' +
      format(minutes)
  };
  return utcDataTime;
}

// 是否超时
export function isTimeOut(utc_str) {
  if (!utc_str) return false;
  let old_obj = new Date(utc_str);
  let new_data = new Date();
  let new_time = new_data.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
  if (old_obj > new_time) return true;
  if (old_obj < new_time) return false;
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function getBase64ToFile(dataurl, filename) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename || '未命名.png', { type: mime });
}
export function iphoneCenter(iphone) {
  let iphones = '' + iphone;
  return iphones.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}
// export function getBase64Image(img, canvas) {
//   console.log(img);
//   canvas.width = img.width;
//   canvas.height = img.height;
//   var ctx = canvas.getContext('2d');
//   ctx.drawImage(img, 0, 0, img.width, img.height);
//   var dataURL = canvas.toDataURL('image/png'); // 可选其他值 image/jpeg
//   return dataURL;
// }
// export function getUrlToBase(imgUrl, canvas, cb) {
//   var image = new Image();
//   image.src = imgUrl + '?v=' + Math.random(); // 处理缓存
//   image.crossOrigin = '*'; // 支持跨域图片
//   image.onload = function () {
//     var base64 = getBase64Image(image, canvas);
//     cb && cb(base64);
//   };
// }

export function objNone(obj) {
  for (let key in obj) {
    return false; //非空
  }
  return true; //为空
}

// 判断权限人员

export function isToUser(data, user) {
  if (data.length !== 0 && user) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].code === user) {
        console.log('true');
        return true;
      } else {
        console.log('false');
        return false;
      }
    }
  } else {
    console.log('falses');
    return false;
  }
}

// input 输入防抖

export function inputDebounce(fn, time) {
  let timer = null;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, time);
  };
}
// input 自动获取焦点
export function onRef(input) {
  if (input != null) {
    input.focus();
  }
}

export function numIndex(prop, align) {
  return function (a, b) {
    let value1 = a[prop];
    let value2 = b[prop];
    console.log(typeof value1);
    if (align === 'positive') {
      //正序
      return value1 - value2;
    } else if (align === 'inverted') {
      //倒序
      return value2 - value1;
    }
  };
}

export function compare(prop, align) {
  return function (a, b) {
    let value1 = a[prop];
    let value2 = b[prop];
    if (align === 'positive') {
      //正序
      return new Date(value1) - new Date(value2);
    } else if (align === 'inverted') {
      //倒序
      return new Date(value2) - new Date(value1);
    }
  };
}
