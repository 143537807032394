import {
  PatchProblemRequesting,
  PatchProblemSuccess,
  PatchProblemError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function patchProblem (state=defalutState,action){
  switch(action.type){
    case PatchProblemRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case PatchProblemSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case PatchProblemError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
