import {
  GetMyTaskRequesting,
  GetMyTaskSuccess,
  GetMyTaskError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function GetMyTask (state=defalutState,action){
  switch(action.type){
    case GetMyTaskRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetMyTaskSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetMyTaskError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
