import {
  DeteleQuestionRequesting,
  DeteleQuestionSuccess,
  DeteleQuestionError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function DeteleQuestion(state = defalutState, action) {
  switch (action.type) {
    case DeteleQuestionRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case DeteleQuestionSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case DeteleQuestionError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
