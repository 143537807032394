import {
  DeleteLabelRequesting,
  DeleteLabelSuccess,
  DeleteLabelError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function DeleteLabel (state=defalutState,action){
  switch(action.type){
    case DeleteLabelRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case DeleteLabelSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case DeleteLabelError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
