import { combineReducers } from 'redux';
import login from './login';
import getBookList from './getBookList';
import getProblemDetail from './getProblemDetail';
import patchProblem from './patchProblem';
import GetUserInfo from './getUserInfo';
import getLabelList from './getLabelList';
import getLabelType from './getLabelType';
import postLabelType from './postLabelType';
import DeleteLabelType from './deleteLabelType';
import AddLabel from './addLabel';
import DeleteLabel from './deleteLabel';
import AddFile from './addFile';
import AddImage from './addImage';
import DeleteImage from './deleteImg';
import GetFolderOne from './getFolderOne';
import GetFolderTwo from './getFolderTwo';
import GetQuestionList from './getQuestionList';
import GetClassList from './getClassList';
import AddLesson from './addLeson';
import AddTask from './addTask';
import GetTaskList from './getTask';
import PatchTask from './patchTask';
import GetBookWork from './getBookWork';
import GetQuestionWork from './getQuestionWork';
import AddTaskDetails from './addTaskDetails';
import GetWorkList from './getWorkList';
import DeteleHour from './deteleHour';
import PatchHour from './patchHour';
import AddTaskFile from './addTaskFile';
import GetTaskFile from './getTaskFile';
import GetTaskPdf from './getTaskPdf';
import DeteleTaskFile from './deteleTaskFile';
import PatchTaskFile from './patchTaskFile';
import DeteleTask from './deteleTask';
import GetStudentClass from './getStudentClass';
import AddAnswer from './addAnswer';
import GetMyTask from './getMyTask';
import GetMarkClass from './getMarkClass';
import GetMarkHour from './getMarkHour';
import GetMarkWork from './getMarkWork';
import GetMarkQuestion from './getMarkQuestion';
import GetCommitList from './getCommitList';
import GetMarkAddList from './getMarkAddList';
import GetMarkDetails from './getMarkDetails';
import AddMarkWork from './addMarkWork';
import GetHourList from './getHourList';
import DeteleQuestion from './deteleQuestion';
import AddExercies from './addExercies';
import GetMyExercies from './getMyExercies';
import DeleteExercies from './deleteExercies';
import PostNateBook from './postNateBook';
import DeleteImages from './deleteImage';
import PathNodeBook from './patchNodeBook';
import GetTwoLabel from './getTwoLabel';
import PostUserInfo from './postUserInfo';
import PostPassWord from './postPassword';
import GetLessonList from './getLessonList';
import GetMyWork from './getMyWork';
import GetStudentStats from './getStudentStats';
import GetTeacherStats from './getTeacherStats';
import GetTeacherSearch from './getTeacherSearch';
import GetAskQuestions from './getAskQuestions';
import GetAskSystem from './getAskSystem';
import GetEditorBase from './getEditorBase';
import PatchEditorBase from './patchEditorBase';
import GetEditorDim from './getEditorDim';
import AddEditorDim from './addEditorDim';
import DeleteEditorDim from './deleteEditorDim';
import PatchEditorDim from './patchEditorDim';
import GetEditorLabel from './getEditorLabel';
import AddEditorLabel from './addEditorLabel';
import DeleteEditorLabel from './deleteEditorLabel';
import PatchEditorLabel from './patchEditorLabel';
import GetEditorClass from './getEditorClass';
import AddEditorClass from './addEditorClass';
import DeleteEditorClass from './deleteEditorClass';
import PatchEditorClass from './patchEditorClass';
import GetEditorMaterial from './getEditorMaterial';
import AddEditorMaterial from './addEditorMaterial';
import DeleteEditorMaterial from './deleteEditorMaterial';
import PatchEditorMaterial from './patchEditorMaterial';
import GetEditorWork from './getEditorWork';
import AddEditorWork from './addEditorWork';
import DeleteEditorWork from './deleteEditorWork';
import PatchEditorWork from './patchEditorWork';
import GetEditorQuestion from './getEditorQuestion';
import AddEditorQuestion from './addEditorQuestion';
import DeleteEditorQuestion from './deleteEditorQuestion';
export default combineReducers({
  login,
  getBookList,
  getProblemDetail,
  patchProblem,
  getLabelList,
  getLabelType,
  GetUserInfo,
  postLabelType,
  DeleteLabelType,
  AddLabel,
  DeleteLabel,
  AddFile,
  AddImage,
  DeleteImage,
  GetFolderOne,
  GetFolderTwo,
  GetQuestionList,
  GetClassList,
  AddLesson,
  AddTask,
  GetTaskList,
  PatchTask,
  GetBookWork,
  GetQuestionWork,
  AddTaskDetails,
  GetWorkList,
  DeteleHour,
  PatchHour,
  AddTaskFile,
  GetTaskFile,
  GetTaskPdf,
  DeteleTaskFile,
  PatchTaskFile,
  DeteleTask,
  GetStudentClass,
  AddAnswer,
  GetMyTask,
  GetMarkClass,
  GetMarkHour,
  GetMarkWork,
  GetMarkQuestion,
  GetCommitList,
  GetMarkAddList,
  GetMarkDetails,
  AddMarkWork,
  GetHourList,
  DeteleQuestion,
  AddExercies,
  GetMyExercies,
  DeleteExercies,
  PostNateBook,
  DeleteImages,
  PathNodeBook,
  GetTwoLabel,
  PostUserInfo,
  PostPassWord,
  GetLessonList,
  GetMyWork,
  GetStudentStats,
  GetTeacherStats,
  GetTeacherSearch,
  GetAskQuestions,
  GetAskSystem,
  GetEditorBase,
  PatchEditorBase,
  GetEditorDim,
  AddEditorDim,
  DeleteEditorDim,
  PatchEditorDim,
  GetEditorLabel,
  AddEditorLabel,
  DeleteEditorLabel,
  PatchEditorLabel,
  GetEditorClass,
  AddEditorClass,
  DeleteEditorClass,
  PatchEditorClass,
  GetEditorMaterial,
  AddEditorMaterial,
  DeleteEditorMaterial,
  PatchEditorMaterial,
  GetEditorWork,
  AddEditorWork,
  DeleteEditorWork,
  PatchEditorWork,
  GetEditorQuestion,
  AddEditorQuestion,
  DeleteEditorQuestion
});
