import {
  DeleteEditorMaterialRequesting,
  DeleteEditorMaterialSuccess,
  DeleteEditorMaterialError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function DeleteEditorMaterial(state = defalutState, action) {
  switch (action.type) {
    case DeleteEditorMaterialRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case DeleteEditorMaterialSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case DeleteEditorMaterialError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
