import {
  GetEditorQuestionRequesting,
  GetEditorQuestionSuccess,
  GetEditorQuestionError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetEditorQuestion(state = defalutState, action) {
  switch (action.type) {
    case GetEditorQuestionRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetEditorQuestionSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetEditorQuestionError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
