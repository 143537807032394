import {
  loginRequesting,
  loginSuccess,
  loginError
} from './../actionType'

const defalutState ={
  loginLodding:false,
  token:null,
  userName:null,
}

export default function login (state=defalutState,action){
  switch(action.type){
    case loginRequesting:
      return Object.assign({},state,{
        loginLodding:true
      })
    case loginSuccess:
      return Object.assign({},state,{
        loginLodding:false,
        token:action.value
      })
    case loginError:
      return Object.assign({},state,{
        loginLodding:false
      })
    default:
      return state
  }
}
