import {
  GetAskSystemRequesting,
  GetAskSystemSuccess,
  GetAskSystemError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetAskSystem(state = defalutState, action) {
  switch (action.type) {
    case GetAskSystemRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetAskSystemSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetAskSystemError:
      return Object.assign({}, state, {
        loading: false,
        data: null
      });
    default:
      return state;
  }
}
