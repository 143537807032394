import {
  DeleteImageRequesting,
  DeleteImageSuccess,
  DeleteImageError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function DeleteImage (state=defalutState,action){
  switch(action.type){
    case DeleteImageRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case DeleteImageSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case DeleteImageError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
