import {
  DeleteEditorQuestionRequesting,
  DeleteEditorQuestionSuccess,
  DeleteEditorQuestionError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function DeleteEditorQuestion(state = defalutState, action) {
  switch (action.type) {
    case DeleteEditorQuestionRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case DeleteEditorQuestionSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case DeleteEditorQuestionError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
