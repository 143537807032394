import {
  PatchEditorBaseRequesting,
  PatchEditorBaseSuccess,
  PatchEditorBaseError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function PatchEditorBase(state = defalutState, action) {
  switch (action.type) {
    case PatchEditorBaseRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case PatchEditorBaseSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case PatchEditorBaseError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
