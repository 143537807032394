import {
  AddEditorMaterialRequesting,
  AddEditorMaterialSuccess,
  AddEditorMaterialError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function AddEditorMaterial(state = defalutState, action) {
  switch (action.type) {
    case AddEditorMaterialRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case AddEditorMaterialSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case AddEditorMaterialError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
