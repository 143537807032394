import {
  GetStudentClassRequesting,
  GetStudentClassSuccess,
  GetStudentClassError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function GetStudentClass (state=defalutState,action){
  switch(action.type){
    case GetStudentClassRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetStudentClassSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetStudentClassError:
      return Object.assign({},state,{
        loading:false,
        data:null
      })
    default:
      return state
  }
}
