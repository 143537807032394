import {
  GetEditorMaterialRequesting,
  GetEditorMaterialSuccess,
  GetEditorMaterialError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetEditorMaterial(state = defalutState, action) {
  switch (action.type) {
    case GetEditorMaterialRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetEditorMaterialSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetEditorMaterialError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
