import {
  GetMyWorkRequesting,
  GetMyWorkSuccess,
  GetMyWorkError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetMyWork(state = defalutState, action) {
  switch (action.type) {
    case GetMyWorkRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetMyWorkSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetMyWorkError:
      return Object.assign({}, state, {
        loading: false,
        data: null
      });
    default:
      return state;
  }
}
