import {
  PatchTaskFileRequesting,
  PatchTaskFileSuccess,
  PatchTaskFileError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function PatchTaskFile (state=defalutState,action){
  switch(action.type){
    case PatchTaskFileRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case PatchTaskFileSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case PatchTaskFileError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
