import {
  PatchEditorDimRequesting,
  PatchEditorDimSuccess,
  PatchEditorDimError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function PatchEditorDim(state = defalutState, action) {
  switch (action.type) {
    case PatchEditorDimRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case PatchEditorDimSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case PatchEditorDimError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
