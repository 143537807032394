import {
  AddMarkWorkRequesting,
  AddMarkWorkSuccess,
  AddMarkWorkError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function AddMarkWork(state = defalutState, action) {
  switch (action.type) {
    case AddMarkWorkRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case AddMarkWorkSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case AddMarkWorkError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
