import {
  PatchEditorLabelRequesting,
  PatchEditorLabelSuccess,
  PatchEditorLabelError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function PatchEditorLabel(state = defalutState, action) {
  switch (action.type) {
    case PatchEditorLabelRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case PatchEditorLabelSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case PatchEditorLabelError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
