import {
  AddTaskFileRequesting,
  AddTaskFileSuccess,
  AddTaskFileError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function AddTaskFile (state=defalutState,action){
  switch(action.type){
    case AddTaskFileRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case AddTaskFileSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case AddTaskFileError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
