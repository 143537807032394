import {
  GetMarkWorkRequesting,
  GetMarkWorkSuccess,
  GetMarkWorkError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetMarkWork(state = defalutState, action) {
  switch (action.type) {
    case GetMarkWorkRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetMarkWorkSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetMarkWorkError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
