import {
  GetTaskPdfRequesting,
  GetTaskPdfSuccess,
  GetTaskPdfError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function GetTaskPdf (state=defalutState,action){
  switch(action.type){
    case GetTaskPdfRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetTaskPdfSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetTaskPdfError:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    default:
      return state
  }
}
