import {
  GetMarkAddListRequesting,
  GetMarkAddListSuccess,
  GetMarkAddListError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetMarkAddList(state = defalutState, action) {
  switch (action.type) {
    case GetMarkAddListRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetMarkAddListSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetMarkAddListError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
