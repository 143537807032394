import {
  AddLabelRequesting,
  AddLabelSuccess,
  AddLabelError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function AddLabel (state=defalutState,action){
  switch(action.type){
    case AddLabelRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case AddLabelSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case AddLabelError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
