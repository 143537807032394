import {
  GetMarkDetailsRequesting,
  GetMarkDetailsSuccess,
  GetMarkDetailsError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetMarkDetails(state = defalutState, action) {
  switch (action.type) {
    case GetMarkDetailsRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetMarkDetailsSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetMarkDetailsError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
