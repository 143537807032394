import {
  GetUserInfoRequesting,
  GetUserInfoSuccess,
  GetUserInfoError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function GetUserInfo (state=defalutState,action){
  switch(action.type){
    case GetUserInfoRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetUserInfoSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetUserInfoError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
