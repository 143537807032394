import {
  GetQuestionWorkRequesting,
  GetQuestionWorkSuccess,
  GetQuestionWorkError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function GetQuestionWork (state=defalutState,action){
  switch(action.type){
    case GetQuestionWorkRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetQuestionWorkSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetQuestionWorkError:
      return Object.assign({},state,{
        loading:false,
        data:null
      })
    default:
      return state
  }
}
