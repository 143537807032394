import {
  GetEditorClassRequesting,
  GetEditorClassSuccess,
  GetEditorClassError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetEditorClass(state = defalutState, action) {
  switch (action.type) {
    case GetEditorClassRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetEditorClassSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetEditorClassError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
