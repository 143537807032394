// 登录
export const loginRequesting = 'loginRequesting';
export const loginSuccess = 'loginSuccess';
export const loginError = 'loginError';

// GetUserInfo

export const GetUserInfoRequesting = 'GetUserInfoRequesting';
export const GetUserInfoSuccess = 'GetUserInfoSuccess';
export const GetUserInfoError = 'GetUserInfoError';

// get book list

export const getBookListRequesting = 'getBookListRequesting';
export const getBookListSuccess = 'getBookListSuccess';
export const getBookListError = 'getBookListError';

// get Problem detail

export const GetProblemDetailRequesting = 'GetProblemDetailRequesting';
export const GetProblemDetailSuccess = 'GetProblemDetailSuccess';
export const GetProblemDetailError = 'GetProblemDetailError';

// patch Problem

export const PatchProblemRequesting = 'PatchProblemRequesting';
export const PatchProblemSuccess = 'PatchProblemSuccess';
export const PatchProblemError = 'PatchProblemError';

// get Label list

export const GetLabelListRequesting = 'GetLabelListRequesting';
export const GetLabelListSuccess = 'GetLabelListSuccess';
export const GetLabelListError = 'GetLabelListError';

// get Label Type

export const GetLabelTypeRequesting = 'GetLabelTypeRequesting';
export const GetLabelTypeSuccess = 'GetLabelTypeSuccess';
export const GetLabelTypeError = 'GetLabelTypeError';

// post Label Type

export const PostLabelTypeRequesting = 'PostLabelTypeRequesting';
export const PostLabelTypeSuccess = 'PostLabelTypeSuccess';
export const PostLabelTypeError = 'PostLabelTypeError';

// detelet Label Type

export const DeleteLabelTypeRequesting = 'DeleteLabelTypeRequesting';
export const DeleteLabelTypeSuccess = 'DeleteLabelTypeSuccess';
export const DeleteLabelTypeError = 'DeleteLabelTypeError';

// add Label

export const AddLabelRequesting = 'AddLabelRequesting';
export const AddLabelSuccess = 'AddLabelSuccess';
export const AddLabelError = 'AddLabelError';

// detelet Label

export const DeleteLabelRequesting = 'DeleteLabelRequesting';
export const DeleteLabelSuccess = 'DeleteLabelSuccess';
export const DeleteLabelError = 'DeleteLabelError';

// add File

export const AddFileRequesting = 'AddFileRequesting';
export const AddFileSuccess = 'AddFileSuccess';
export const AddFileError = 'AddFileError';

// add Image

export const AddImageRequesting = 'AddImageRequesting';
export const AddImageSuccess = 'AddImageSuccess';
export const AddImageError = 'AddImageError';

// Delete Image

export const DeleteImageRequesting = 'DeleteImageRequesting';
export const DeleteImageSuccess = 'DeleteImageSuccess';
export const DeleteImageError = 'DeleteImageError';

// get folder one

export const GetFolderOneRequesting = 'GetFolderOneRequesting';
export const GetFolderOneSuccess = 'GetFolderOneSuccess';
export const GetFolderOneError = 'GetFolderOneError';

// get folder two

export const GetFolderTwoRequesting = 'GetFolderTwoRequesting';
export const GetFolderTwoSuccess = 'GetFolderTwoSuccess';
export const GetFolderTwoError = 'GetFolderTwoError';

//  GetQuestionList

export const GetQuestionListRequesting = 'GetQuestionListRequesting';
export const GetQuestionListSuccess = 'GetQuestionListSuccess';
export const GetQuestionListError = 'GetQuestionListError';

// Teacher

// DeteleQuestion

export const DeteleQuestionRequesting = 'DeteleQuestionRequesting';
export const DeteleQuestionSuccess = 'DeteleQuestionSuccess';
export const DeteleQuestionError = 'DeteleQuestionError';

// getClassList

export const GetClassListRequesting = 'GetClassListRequesting';
export const GetClassListSuccess = 'GetClassListSuccess';
export const GetClassListError = 'GetClassListError';

// add Lesson

export const AddLessonRequesting = 'AddLessonRequesting';
export const AddLessonSuccess = 'AddLessonSuccess';
export const AddLessonError = 'AddLessonError';

// add Task

export const AddTaskRequesting = 'AddTaskRequesting';
export const AddTaskSuccess = 'AddTaskSuccess';
export const AddTaskError = 'AddTaskError';

// getTaskList

export const GetTaskListRequesting = 'GetTaskListRequesting';
export const GetTaskListSuccess = 'GetTaskListSuccess';
export const GetTaskListError = 'GetTaskListError';

// patch task

export const PatchTaskRequesting = 'PatchTaskRequesting';
export const PatchTaskSuccess = 'PatchTaskSuccess';
export const PatchTaskError = 'PatchTaskError';

// get book work

export const GetBookWorkRequesting = 'GetBookWorkRequesting';
export const GetBookWorkSuccess = 'GetBookWorkSuccess';
export const GetBookWorkError = 'GetBookWorkError';

//  GetQuestionWork

export const GetQuestionWorkRequesting = 'GetQuestionWorkRequesting';
export const GetQuestionWorkSuccess = 'GetQuestionWorkSuccess';
export const GetQuestionWorkError = 'GetQuestionWorkError';

// add Task Details

export const AddTaskDetailsRequesting = 'AddTaskDetailsRequesting';
export const AddTaskDetailsSuccess = 'AddTaskDetailsSuccess';
export const AddTaskDetailsError = 'AddTaskDetailsError';

// GetWorkList

export const GetWorkListRequesting = 'GetWorkListRequesting';
export const GetWorkListSuccess = 'GetWorkListSuccess';
export const GetWorkListError = 'GetWorkListError';

// deteletHour

export const DeteleHourRequesting = 'DeteleHourRequesting';
export const DeteleHourSuccess = 'DeteleHourSuccess';
export const DeteleHourError = 'DeteleHourError';

// patch Hour

export const PatchHourRequesting = 'PatchHourRequesting';
export const PatchHourSuccess = 'PatchHourSuccess';
export const PatchHourError = 'PatchHourError';

// add Task File

// PostNatework

export const PostNateBookRequesting = 'PostNateBookRequesting';
export const PostNateBookSuccess = 'PostNateBookSuccess';
export const PostNateBookError = 'PostNateBookError';

export const AddTaskFileRequesting = 'AddTaskFileRequesting';
export const AddTaskFileSuccess = 'AddTaskFileSuccess';
export const AddTaskFileError = 'AddTaskFileError';

// getTaskFile

export const GetTaskFileRequesting = 'GetTaskFileRequesting';
export const GetTaskFileSuccess = 'GetTaskFileSuccess';
export const GetTaskFileError = 'GetTaskFileError';

// GetTaskPdf

export const GetTaskPdfRequesting = 'GetTaskPdfRequesting';
export const GetTaskPdfSuccess = 'GetTaskPdfSuccess';
export const GetTaskPdfError = 'GetTaskPdfError';

// deteletTaskFile

export const DeteleTaskFileRequesting = 'DeteleTaskFileRequesting';
export const DeteleTaskFileSuccess = 'DeteleTaskFileSuccess';
export const DeteleTaskFileError = 'DeteleTaskFileError';

// patch task File

export const PatchTaskFileRequesting = 'PatchTaskFileRequesting';
export const PatchTaskFileSuccess = 'PatchTaskFileSuccess';
export const PatchTaskFileError = 'PatchTaskFileError';

// deteletTask

export const DeteleTaskRequesting = 'DeteleTaskRequesting';
export const DeteleTaskSuccess = 'DeteleTaskSuccess';
export const DeteleTaskError = 'DeteleTaskError';

// student

//  GetStudentClass

export const GetStudentClassRequesting = 'GetStudentClassRequesting';
export const GetStudentClassSuccess = 'GetStudentClassSuccess';
export const GetStudentClassError = 'GetStudentClassError';

// AddAnswer

export const AddAnswerRequesting = 'AddAnswerRequesting';
export const AddAnswerSuccess = 'AddAnswerSuccess';
export const AddAnswerError = 'AddAnswerError';

// AddExercies

export const AddExerciesRequesting = 'AddExerciesRequesting';
export const AddExerciesSuccess = 'AddExerciesSuccess';
export const AddExerciesError = 'AddExerciesError';

// GetMyTask

export const GetMyTaskRequesting = 'GetMyTaskRequesting';
export const GetMyTaskSuccess = 'GetMyTaskSuccess';
export const GetMyTaskError = 'GetMyTaskError';

// DeleteExercies

export const DeleteExerciesRequesting = 'DeleteExerciesRequesting';
export const DeleteExerciesSuccess = 'DeleteExerciesSuccess';
export const DeleteExerciesError = 'DeleteExerciesError';

// GetMaskClass

export const GetMarkClassRequesting = 'GetMarkClassRequesting';
export const GetMarkClassSuccess = 'GetMarkClassSuccess';
export const GetMarkClassError = 'GetMarkClassError';

// DeleteImages

export const DeleteImagesRequesting = 'DeleteImagesRequesting';
export const DeleteImagesSuccess = 'DeleteImagesSuccess';
export const DeleteImagesError = 'DeleteImagesError';

// GetMarkHour

export const GetMarkHourRequesting = 'GetMarkHourRequesting';
export const GetMarkHourSuccess = 'GetMarkHourSuccess';
export const GetMarkHourError = 'GetMarkHourError';

// GetMarkWork

export const GetMarkWorkRequesting = 'GetMarkWorkRequesting';
export const GetMarkWorkSuccess = 'GetMarkWorkSuccess';
export const GetMarkWorkError = 'GetMarkWorkError';

// GetMyExercies

export const GetMyExerciesRequesting = 'GetMyExerciesRequesting';
export const GetMyExerciesSuccess = 'GetMyExerciesSuccess';
export const GetMyExerciesError = 'GetMyExerciesError';

// GetMarkQuestion

export const GetMarkQuestionRequesting = 'GetMarkQuestionRequesting';
export const GetMarkQuestionSuccess = 'GetMarkQuestionSuccess';
export const GetMarkQuestionError = 'GetMarkQuestionError';

// GetMarkAddList

export const GetMarkAddListRequesting = 'GetMarkAddListRequesting';
export const GetMarkAddListSuccess = 'GetMarkAddListSuccess';
export const GetMarkAddListError = 'GetMarkAddListError';

// GetMarkDetails

export const GetMarkDetailsRequesting = 'GetMarkDetailsRequesting';
export const GetMarkDetailsSuccess = 'GetMarkDetailsSuccess';
export const GetMarkDetailsError = 'GetMarkDetailsError';

// AddMarkWork

export const AddMarkWorkRequesting = 'AddMarkWorkRequesting';
export const AddMarkWorkSuccess = 'AddMarkWorkSuccess';
export const AddMarkWorkError = 'AddMarkWorkError';

// GetHourList

export const GetHourListRequesting = 'GetHourListRequesting';
export const GetHourListSuccess = 'GetHourListSuccess';
export const GetHourListError = 'GetHourListError';

// PathNodeBook

export const PathNodeBookRequesting = 'PathNodeBookRequesting';
export const PathNodeBookSuccess = 'PathNodeBookSuccess';
export const PathNodeBookError = 'PathNodeBookError';

// GetTwoLabel

export const GetTwoLabelRequesting = 'GetTwoLabelRequesting';
export const GetTwoLabelSuccess = 'GetTwoLabelSuccess';
export const GetTwoLabelError = 'GetTwoLabelError';

// PostPassWord

export const PostPassWordRequesting = 'PostPassWordRequesting';
export const PostPassWordSuccess = 'PostPassWordSuccess';
export const PostPassWordError = 'PostPassWordError';

// PostUserInfo

export const PostUserInfoRequesting = 'PostUserInfoRequesting';
export const PostUserInfoSuccess = 'PostUserInfoSuccess';
export const PostUserInfoError = 'PostUserInfoError';

// GetMyWork

export const GetMyWorkRequesting = 'GetMyWorkRequesting';
export const GetMyWorkSuccess = 'GetMyWorkSuccess';
export const GetMyWorkError = 'GetMyWorkError';

// GetAskQuestions

export const GetAskQuestionsRequesting = 'GetAskQuestionsRequesting';
export const GetAskQuestionsSuccess = 'GetAskQuestionsSuccess';
export const GetAskQuestionsError = 'GetAskQuestionsError';

// GetTeacherSearch

export const GetTeacherSearchRequesting = 'GetTeacherSearchRequesting';
export const GetTeacherSearchSuccess = 'GetTeacherSearchSuccess';
export const GetTeacherSearchError = 'GetTeacherSearchError';

// GetLessonList

export const GetLessonListRequesting = 'GetLessonListRequesting';
export const GetLessonListSuccess = 'GetLessonListSuccess';
export const GetLessonListError = 'GetLessonListError';

// GetAskSystem

export const GetAskSystemRequesting = 'GetAskSystemRequesting';
export const GetAskSystemSuccess = 'GetAskSystemSuccess';
export const GetAskSystemError = 'GetAskSystemError';

// GetEditorBase

export const GetEditorBaseRequesting = 'GetEditorBaseRequesting';
export const GetEditorBaseSuccess = 'GetEditorBaseSuccess';
export const GetEditorBaseError = 'GetEditorBaseError';

// PatchEditorBase

export const PatchEditorBaseRequesting = 'PatchEditorBaseRequesting';
export const PatchEditorBaseSuccess = 'PatchEditorBaseSuccess';
export const PatchEditorBaseError = 'PatchEditorBaseError';

// GetEditorDim

export const GetEditorDimRequesting = 'GetEditorDimRequesting';
export const GetEditorDimSuccess = 'GetEditorDimSuccess';
export const GetEditorDimError = 'GetEditorDimError';

// AddEditorDim

export const AddEditorDimRequesting = 'AddEditorDimRequesting';
export const AddEditorDimSuccess = 'AddEditorDimSuccess';
export const AddEditorDimError = 'AddEditorDimError';

// DeleteEditorDim

export const DeleteEditorDimRequesting = 'DeleteEditorDimRequesting';
export const DeleteEditorDimSuccess = 'DeleteEditorDimSuccess';
export const DeleteEditorDimError = 'DeleteEditorDimError';

// PatchEditorDim

export const PatchEditorDimRequesting = 'PatchEditorDimRequesting';
export const PatchEditorDimSuccess = 'PatchEditorDimSuccess';
export const PatchEditorDimError = 'PatchEditorDimError';

// GetEditorLabel

export const GetEditorLabelRequesting = 'GetEditorLabelRequesting';
export const GetEditorLabelSuccess = 'GetEditorLabelSuccess';
export const GetEditorLabelError = 'GetEditorLabelError';

// AddEditorLabel

export const AddEditorLabelRequesting = 'AddEditorLabelRequesting';
export const AddEditorLabelSuccess = 'AddEditorLabelSuccess';
export const AddEditorLabelError = 'AddEditorLabelError';

// DeleteEditorLabel

export const DeleteEditorLabelRequesting = 'DeleteEditorLabelRequesting';
export const DeleteEditorLabelSuccess = 'DeleteEditorLabelSuccess';
export const DeleteEditorLabelError = 'DeleteEditorLabelError';

// PatchEditorLabel

export const PatchEditorLabelRequesting = 'PatchEditorLabelRequesting';
export const PatchEditorLabelSuccess = 'PatchEditorLabelSuccess';
export const PatchEditorLabelError = 'PatchEditorLabelError';

// GetEditorClass

export const GetEditorClassRequesting = 'GetEditorClassRequesting';
export const GetEditorClassSuccess = 'GetEditorClassSuccess';
export const GetEditorClassError = 'GetEditorClassError';

// AddEditorClass

export const AddEditorClassRequesting = 'AddEditorClassRequesting';
export const AddEditorClassSuccess = 'AddEditorClassSuccess';
export const AddEditorClassError = 'AddEditorClassError';

// DeleteEditorClass

export const DeleteEditorClassRequesting = 'DeleteEditorClassRequesting';
export const DeleteEditorClassSuccess = 'DeleteEditorClassSuccess';
export const DeleteEditorClassError = 'DeleteEditorClassError';

// PatchEditorClass

export const PatchEditorClassRequesting = 'PatchEditorClassRequesting';
export const PatchEditorClassSuccess = 'PatchEditorClassSuccess';
export const PatchEditorClassError = 'PatchEditorClassError';

// GetEditorMaterial

export const GetEditorMaterialRequesting = 'GetEditorMaterialRequesting';
export const GetEditorMaterialSuccess = 'GetEditorMaterialSuccess';
export const GetEditorMaterialError = 'GetEditorMaterialError';

// AddEditorMaterial

export const AddEditorMaterialRequesting = 'AddEditorMaterialRequesting';
export const AddEditorMaterialSuccess = 'AddEditorMaterialSuccess';
export const AddEditorMaterialError = 'AddEditorMaterialError';

// DeleteEditorMaterial

export const DeleteEditorMaterialRequesting = 'DeleteEditorMaterialRequesting';
export const DeleteEditorMaterialSuccess = 'DeleteEditorMaterialSuccess';
export const DeleteEditorMaterialError = 'DeleteEditorMaterialError';

// PatchEditorMaterial

export const PatchEditorMaterialRequesting = 'PatchEditorMaterialRequesting';
export const PatchEditorMaterialSuccess = 'PatchEditorMaterialSuccess';
export const PatchEditorMaterialError = 'PatchEditorMaterialError';

// GetEditorWork

export const GetEditorWorkRequesting = 'GetEditorWorkRequesting';
export const GetEditorWorkSuccess = 'GetEditorWorkSuccess';
export const GetEditorWorkError = 'GetEditorWorkError';

// AddEditorWork

export const AddEditorWorkRequesting = 'AddEditorWorkRequesting';
export const AddEditorWorkSuccess = 'AddEditorWorkSuccess';
export const AddEditorWorkError = 'AddEditorWorkError';

// DeleteEditorWork

export const DeleteEditorWorkRequesting = 'DeleteEditorWorkRequesting';
export const DeleteEditorWorkSuccess = 'DeleteEditorWorkSuccess';
export const DeleteEditorWorkError = 'DeleteEditorWorkError';

// PatchEditorWork

export const PatchEditorWorkRequesting = 'PatchEditorWorkRequesting';
export const PatchEditorWorkSuccess = 'PatchEditorWorkSuccess';
export const PatchEditorWorkError = 'PatchEditorWorkError';

// GetEditorQuestion

export const GetEditorQuestionRequesting = 'GetEditorQuestionRequesting';
export const GetEditorQuestionSuccess = 'GetEditorQuestionSuccess';
export const GetEditorQuestionError = 'GetEditorQuestionError';

// AddEditorQuestion

export const AddEditorQuestionRequesting = 'AddEditorQuestionRequesting';
export const AddEditorQuestionSuccess = 'AddEditorQuestionSuccess';
export const AddEditorQuestionError = 'AddEditorQuestionError';

// DeleteEditorQuestion

export const DeleteEditorQuestionRequesting = 'DeleteEditorQuestionRequesting';
export const DeleteEditorQuestionSuccess = 'DeleteEditorQuestionSuccess';
export const DeleteEditorQuestionError = 'DeleteEditorQuestionError';

// PatchEditorQuestion

export const PatchEditorQuestionRequesting = 'PatchEditorQuestionRequesting';
export const PatchEditorQuestionSuccess = 'PatchEditorQuestionSuccess';
export const PatchEditorQuestionError = 'PatchEditorQuestionError';

// GetStudentStats

export const GetStudentStatsRequesting = 'GetStudentStatsRequesting';
export const GetStudentStatsSuccess = 'GetStudentStatsSuccess';
export const GetStudentStatsError = 'GetStudentStatsError';

// GetTeacherStats

export const GetTeacherStatsRequesting = 'GetTeacherStatsRequesting';
export const GetTeacherStatsSuccess = 'GetTeacherStatsSuccess';
export const GetTeacherStatsError = 'GetTeacherStatsError';

// GetCommitList

export const GetCommitListRequesting = 'GetCommitListRequesting';
export const GetCommitListSuccess = 'GetCommitListSuccess';
export const GetCommitListError = 'GetCommitListError';
