import {
  GetTwoLabelRequesting,
  GetTwoLabelSuccess,
  GetTwoLabelError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetTwoLabel(state = defalutState, action) {
  switch (action.type) {
    case GetTwoLabelRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetTwoLabelSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetTwoLabelError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
