import {
  GetTeacherSearchRequesting,
  GetTeacherSearchSuccess,
  GetTeacherSearchError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetTeacherSearch(state = defalutState, action) {
  switch (action.type) {
    case GetTeacherSearchRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetTeacherSearchSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetTeacherSearchError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
