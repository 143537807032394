import {
  DeleteImagesRequesting,
  DeleteImagesSuccess,
  DeleteImagesError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function DeleteImages(state = defalutState, action) {
  switch (action.type) {
    case DeleteImagesRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case DeleteImagesSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case DeleteImagesError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
