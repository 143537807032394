import {
  PatchTaskRequesting,
  PatchTaskSuccess,
  PatchTaskError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function PatchTask (state=defalutState,action){
  switch(action.type){
    case PatchTaskRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case PatchTaskSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case PatchTaskError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
