import {
  PatchEditorClassRequesting,
  PatchEditorClassSuccess,
  PatchEditorClassError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function PatchEditorClass(state = defalutState, action) {
  switch (action.type) {
    case PatchEditorClassRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case PatchEditorClassSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case PatchEditorClassError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
