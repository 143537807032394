import {
  GetTeacherStatsRequesting,
  GetTeacherStatsSuccess,
  GetTeacherStatsError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetTeacherStats(state = defalutState, action) {
  switch (action.type) {
    case GetTeacherStatsRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetTeacherStatsSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetTeacherStatsError:
      return Object.assign({}, state, {
        loading: false,
        data: null
      });
    default:
      return state;
  }
}
