import {
  AddTaskRequesting,
  AddTaskSuccess,
  AddTaskError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function AddTask (state=defalutState,action){
  switch(action.type){
    case AddTaskRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case AddTaskSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case AddTaskError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
