import {
  DeleteEditorClassRequesting,
  DeleteEditorClassSuccess,
  DeleteEditorClassError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function DeleteEditorClass(state = defalutState, action) {
  switch (action.type) {
    case DeleteEditorClassRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case DeleteEditorClassSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case DeleteEditorClassError:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
