import {
  GetLabelListRequesting,
  GetLabelListSuccess,
  GetLabelListError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function getLabelList (state=defalutState,action){
  switch(action.type){
    case GetLabelListRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case GetLabelListSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case GetLabelListError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
