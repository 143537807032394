import {
  GetMarkClassRequesting,
  GetMarkClassSuccess,
  GetMarkClassError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetMarkClass(state = defalutState, action) {
  switch (action.type) {
    case GetMarkClassRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetMarkClassSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetMarkClassError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
