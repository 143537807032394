import {
  AddLessonRequesting,
  AddLessonSuccess,
  AddLessonError
} from './../actionType'

const defalutState ={
  loading:false,
  data:null,
}

export default function AddLesson (state=defalutState,action){
  switch(action.type){
    case AddLessonRequesting:
      return Object.assign({},state,{
        loading:true
      })
    case AddLessonSuccess:
      return Object.assign({},state,{
        loading:false,
        data:action.value
      })
    case AddLessonError:
      return Object.assign({},state,{
        loading:false
      })
    default:
      return state
  }
}
