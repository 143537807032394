import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Loading from './compoment/loading/loading';
import isLogin from './isLogin';
const NoFile = lazy(() => import('./compoment/noFile/noFile'));
const IndexPage = lazy(() => import('./indexPage/index/index'));
const LoginPage = lazy(() => import('./indexPage/login/login'));
const EditorPage = lazy(() => import('./indexPage/editor/editor'));
const TeacherPage = lazy(() => import('./indexPage/teacher/index'));
const TaskPage = lazy(() => import('./indexPage/teacher/compoment/taskPage'));
const TaskFile = lazy(() => import('./indexPage/teacher/compoment/taskFile'));
const StudentPage = lazy(() => import('./indexPage/student/index'));
const MarkTeacher = lazy(() => import('./indexPage/markTeacher/index'));
const MarkHour = lazy(() =>
  import('./indexPage/markTeacher/compoment/markHour')
);
const MarkWork = lazy(() =>
  import('./indexPage/markTeacher/compoment/markWork')
);
const MarkQuestion = lazy(() =>
  import('./indexPage/markTeacher/compoment/markQuestion')
);
const MarkAddlist = lazy(() =>
  import('./indexPage/markTeacher/compoment/markAddList')
);
const MarkDetaIls = lazy(() =>
  import('./indexPage/markTeacher/compoment/markDetails')
);
const UserCenter = lazy(() => import('./indexPage/userCenter'));
const TeacherSearch = lazy(() => import('./indexPage/teacherSearch'));
const AskQuestions = lazy(() => import('./indexPage/askQuestions'));
const EditorMan = lazy(() => import('./indexPage/editor/editorMan'));
class Routers extends React.Component {
  // componentWillUnmount(){
  //   window.localStorage.setItem('tabsKey','/')
  // }
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" component={isLogin(IndexPage)} />
            <Route path="/login" component={LoginPage} />
            <Route path="/editor" component={isLogin(EditorPage)} />
            <Route path="/editorMan" component={isLogin(EditorMan)} />
            <Route path="/teacher" component={isLogin(TeacherPage)} />
            <Route path="/markTeacher" component={isLogin(MarkTeacher)} />
            <Route path="/markHour/:classId/" component={isLogin(MarkHour)} />
            <Route path="/markWork/:hourId" component={isLogin(MarkWork)} />
            <Route path="/userCenter" component={isLogin(UserCenter)} />
            <Route path="/search" component={isLogin(TeacherSearch)} />
            <Route path="/askQuestion" component={isLogin(AskQuestions)} />
            <Route
              path="/markDetails/:addId/"
              component={isLogin(MarkDetaIls)}
            />
            <Route
              path="/markAddList/:questionId"
              component={isLogin(MarkAddlist)}
            />
            <Route
              path="/markQuestion/:workId"
              component={isLogin(MarkQuestion)}
            />
            <Route
              path="/task/:lessonId/:lessonName/:classId"
              component={isLogin(TaskPage)}
            />

            <Route path="/taskFile/:lessonId" component={isLogin(TaskFile)} />
            <Route path="/student" component={isLogin(StudentPage)} />
            <Route path="*" component={NoFile} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default Routers;
