import {
  GetEditorDimRequesting,
  GetEditorDimSuccess,
  GetEditorDimError
} from './../actionType';

const defalutState = {
  loading: false,
  data: null
};

export default function GetEditorDim(state = defalutState, action) {
  switch (action.type) {
    case GetEditorDimRequesting:
      return Object.assign({}, state, {
        loading: true
      });
    case GetEditorDimSuccess:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    case GetEditorDimError:
      return Object.assign({}, state, {
        loading: false,
        data: action.value
      });
    default:
      return state;
  }
}
